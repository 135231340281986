<template>
  <div>
    
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-card-body>
            <div class="d-flex justify-content-end flex-wrap">
              <b-form-group
                label="Sort"
                label-size="sm"
                label-for="sortBySelect"
                class="mb-md-0 ml-2"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                  >
                    <template #first>
                      <option value="">
                        none
                      </option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortBy"
                  >
                    <option :value="false">
                      ASC
                    </option>
                    <option :value="true">
                      DESC
                    </option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>

              <b-form-group
                label="Search"
                label-size="sm"
                label-for="filterInput"
                class="mb-0 ml-2"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-card-body>

          <b-table
            striped
            hover
            responsive 
            show-empty
            class="position-relative"
            :per-page="perPage"
            :current-page="currentPage"
            :items="itemsFiltered"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(name)="data">
              <div class="d-flex flex-row">
                <b-avatar v-if="data.item.image !== null"
                  class="mr-50 d-flex"
                  :src="`${data.item.image}`"
                /> 
                <b-avatar v-else
                  class="mr-50 d-flex"
                  variant="primary"
                  :text="data.item.name | initial"
                />
                <div class="d-flex flex-column">
                  <p class="mt-50">{{ data.item.name }}</p>
                </div>
              </div>
            </template>
          </b-table>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {
    BRow, BCol,
    BCard, BCardBody,
    BButton, BFormGroup, BInputGroup, BFormInputGroup, 
    BFormSelect, BFormInput, BInputGroupAppend,
    BPagination, BTable, BAvatar, BBadge,
    BDropdown, BDropdownItem
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'

  export default {
    components: {
      BRow, BCol,
      BCard, BCardBody,
      BButton, BFormGroup, BInputGroup, BFormInputGroup, 
      BFormSelect, BFormInput, BInputGroupAppend,
      BPagination, BTable, BAvatar, BBadge,
      BDropdown, BDropdownItem
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        perPage: 50,
				pageOptions: [10, 50, 100],
				totalRows: 1,
				currentPage: 1,
				sortBy: '',
				sortDesc: false,
				sortDirection: 'asc',
				filter: null,
				filterOn: [],
				infoModal: {
					id: 'info-modal',
					title: '',
					content: '',
				},
				fields: [
					{ key: 'name', label: 'Name', sortable: true },
					{ key: 'created_by', label: 'Created By', sortable: true },
					{ key: 'location', label: 'Location', sortable: true },
					{ key: 'check_in', label: 'Check In' }
				],
				items: [],

        active_role: null
      }
    },
    computed: {
			sortOptions() {
				// Create an options list from our fields
				return this.fields
					.filter(f => f.sortable)
					.map(f => ({ text: f.label, value: f.key }))
			},
      itemsFiltered() {
        let items = this.items

        if(this.status_event !== "all") {
          items = items.filter(item => {
            return item.status == this.status_event
          })
        }

        return items
      }
		},
		created() {
      this.checkAuth()
    },
    mounted() {
      this.active_role = localStorage.getItem('role')
      this.getDataEvent()
    },
    methods: {
			info(item, index, button) {
				this.infoModal.title = `Row index: ${index}`
				this.infoModal.content = JSON.stringify(item, null, 2)
				this.$root.$emit('bv::show::modal', this.infoModal.id, button)
			},
      onFiltered(filteredItems) {
				// Trigger pagination to update the number of buttons/pages due to filtering
				this.totalRows = filteredItems.length
				this.currentPage = 1
			},

      async getDataEvent() {
        let id = this.$route.params.id
        let cid = localStorage.getItem('client_id')
				
				let dataAPI = null
        if(this.active_role == 'corporate') {
          const config = {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
          }
          dataAPI = await this.$axios.get(`${cid}/log/member/all/${id}`, config)
        }

        let event = dataAPI.data.data
        event.forEach(item => {
          let eventItem = {
            id: item.id,
            name: item.name,
            image: item.image,
            start_date: item.start_date.replace(" | ", " "),
            end_date: item.end_date.replace(" | ", " "),
            created_by: item.created_by,
            location: item.location,
            check_in: item.check_in
          }

          this.items.push(eventItem)
        })
      },
    }
  }
</script>